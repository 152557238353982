#oocsi-graphvis {
    font-family: sans-serif;
    user-select: none;
    -webkit-user-select: none;
    background: #fafafa;
    box-sizing: content-box;
    overflow: hidden;
}
#oocsi-graphvis .node {
    fill: white;
    stroke: black;
    stroke-width: 1.5px;
}
#oocsi-graphvis .node.channel {
    fill: rgb(200, 205, 255);
}
#oocsi-graphvis .node.client {
    fill: rgb(255, 200, 205);
}
#oocsi-graphvis .link {
    stroke: rgba(0, 0, 0, 0.7);
    stroke-dasharray: 1,0;
}
#oocsi-graphvis .link.client {
    stroke: rgba(0, 0, 0, 0.9);
    stroke-dasharray: 4,4;
}
#oocsi-graphvis .link.channel {
    stroke: rgba(0, 0, 0, 0.9);
    stroke-dasharray: 1,0;
}
#oocsi-graphvis .link.subscription {
    stroke: rgba(0, 0, 0, 0.5);
    stroke-dasharray: 1,3;
}
#oocsi-graphvis .moving-dot {
    fill: black;
}
#oocsi-graphvis .moving-dot.shadow {
    fill: #999;
    opacity: 0.5;
}
#oocsi-graphvis .moving-dot.generated {
    fill: #9c9;
    opacity: 0.5;
}
#oocsi-graphvis .label {
    font-size: 12px;
    fill: black;
    text-anchor: right;
}

#oocsi-graphvis .widget {
    position: fixed;
    width: 300px;
    height: 85px;
    background: rgba(200, 200, 205, 0.3);
    padding: 0.5rem;
}
#oocsi-graphvis .widget .text {
    font-size: 12px;
    color: rgb(50, 50, 100);
}
#oocsi-graphvis .sparkline {
    margin: 0.5rem 0 0;
}
#oocsi-graphvis .messages {
    top: 100px;
    left: 50px;
}
#oocsi-graphvis .search {
    top: 220px;
    left: 50px;
    height: unset;
}
#oocsi-graphvis .search input {
    width: 100%;
    padding: 0.5rem 0 0;
    font-size: 1rem;
    font-weight: 200;
    border: 0;
    border-bottom: 1px solid #ccc;
    background: transparent;
    outline: none;
}
#oocsi-graphvis .search input:focus {
    border-bottom: 1px solid black;
}
#oocsi-graphvis .clients {
    top: 100px;
    right: 50px;
}
#oocsi-graphvis .subscriptions {
    top: 220px;
    right: 50px;
}
